import React from "react";
import Header from "./header";
import Navbar from "./navbar";
import Showcase from "./showcase";
import Ponuda from "./ponuda";
import Partners from "./partners";
import Footer from "./footer";

function Pocetna() {
  return (
    <div className="App">
      <Header />
      <Navbar />
      <Showcase />
      <hr className="br-line"></hr>
      <Ponuda />
      <hr className="br-line"></hr>
      <Partners />
      <Footer />
    </div>
  );
}

export default Pocetna;
