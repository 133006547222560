import React, { Component } from "react";
import { BrowserRouter as Router, Switch, NavLink } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import "../css/showcase.css";

function Showcase() {
  return (
    <Zoom>
      <div className="showcase">
        <h2 className="show_title">
          {" "}
          <strong>СИГУРНОСТА</strong> Е ВО НАШИ РАЦЕ
        </h2>

        <h5 className="title2">
          {" "}
          Изградете светла иднина за Вас и Вашето семејство
        </h5>

        <div className="contact-container">
          <div>
            <h5 className="contact-text">КОГА ВИ ТРЕБА ПАРТНЕР</h5>
            <p>
              Побарајте го нашиот агент кој се наоѓа најблиску до Вас и ние ќе
              се погрижиме да го најдеме вистинскиот производ за Вас.
            </p>
          </div>
          <NavLink className="contact-a" to="/kontakt">
            КОНТАКТ
          </NavLink>
        </div>
      </div>
    </Zoom>
  );
}

export default Showcase;
