import React from "react";
import Fade from "react-reveal/Fade";

import "../css/partners.css";

function partners() {
  const logos = [
    {
      id: 1,
      imageUrl: "./logos/triglav.png",
      href: "https://www.triglav.mk/mk/",
    },
    {
      id: 2,
      imageUrl: "./logos/uniqa.png",
      href: "https://www.uniqa.mk/home/",
    },
    {
      id: 3,
      imageUrl: "./logos/halk.png",
      href: "https://www.halkinsurance.com.mk/",
    },
    {
      id: 4,
      imageUrl: "./logos/croatia.png",
      href: "https://web.crosig.mk/",
    },
    {
      id: 5,
      imageUrl: "./logos/winner.png",
      href: "http://www.winner.mk/",
    },
    {
      id: 6,
      imageUrl: "./logos/eurolink.png",
      href: "https://eurolink.com.mk/",
    },
    {
      id: 7,
      imageUrl: "./logos/grawe.png",
      href: "https://www.grawe.mk/",
    },
    {
      id: 8,
      imageUrl: "./logos/viena.png",
      href: "https://www.insumak.mk/",
    },
  ];

  const logosZ = [
    {
      id: 9,
      imageUrl: "./logos/croatia-zivot.png",
      href: "https://cro.mk/",
    },
    {
      id: 10,
      imageUrl: "./logos/uniqa-zivot.png",
      href:
        "https://www.uniqa.mk/home/01_Fizicki_lica/01_Zivotno_osiguruvanje/",
    },
    {
      id: 11,
      imageUrl: "./logos/grawe-zivot.png",
      href: "https://www.grawe.mk/osiguruva%D1%9Ae-na-zhivot/",
    },
    {
      id: 12,
      imageUrl: "./logos/winner-zivot.png",
      href: "https://winnerlife.mk/",
    },
  ];

  return (
    <div className="part-container">
      <Fade left cascade>
        <h2 className="part-title">НАШИ ПАРТНЕРИ</h2>
      </Fade>
      <Fade>
        <div className="part-logos">
          {logos.map((logo) => (
            <a href={logo.href} target="_blank" rel="noreferrer" key={logo.id}>
              {" "}
              <img className="logo-part" src={logo.imageUrl} alt="logo"></img>
            </a>
          ))}
        </div>
      </Fade>
      <br />

      <Fade>
        <div className="part-logos">
          {logosZ.map((logoZ) => (
            <a
              href={logoZ.href}
              target="_blank"
              rel="noreferrer"
              key={logoZ.id}
            >
              {" "}
              <img className="logo-part" src={logoZ.imageUrl} alt="logo"></img>
            </a>
          ))}
        </div>
      </Fade>
    </div>
  );
}

export default partners;
