import React, { useState, useEffect, useRef } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import { useOnClickOutside } from "./components/hooks";
import LoadingScreen from "./components/loadingscreen";
import Pocetna from "./components/pocetna";
import Zanas from "./components/zanas";
import Uslugi from "./components/uslugi";
import Kontakt from "./components/kontakt";
import ScrollToTop from "./components/ScrollToTop";
import Burger from "./components/burger";
import Menu from "./components/menu";
import Regulativa from "./components/regulativa";

function App() {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    setTimeout(() => setLoading(false), 6000);
  }, []);

  return (
    <>
      {loading === false ? (
        <HashRouter hashType="slash">
          <ScrollToTop />
          <div className="App">
            <div ref={node}>
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} />
            </div>
            <Switch>
              <Route path="/zanas" component={Zanas} />
              <Route path="/uslugi" component={Uslugi} />
              <Route path="/kontakt" component={Kontakt} />
              <Route path="/regulativa" component={Regulativa} />
              <Route path="/" component={Pocetna} />
            </Switch>
          </div>
        </HashRouter>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

export default App;
