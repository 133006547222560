import React from "react";
import Footer from "./footer";
import Header from "./header";
import Navbar from "./navbar";
import Fade from "react-reveal/Fade";
import "../css/uslugi.css";

function Uslugi() {
  return (
    <div>
      <Header />
      <Navbar />
      <div className="uslugi-container">
        <h1 className="uslugi-title">
          <Fade left cascade>
            УСЛУГИ :
          </Fade>
        </h1>
        <Fade>
          <div className="uslugi-text-container">
            <ol className="ulsugi-list">
              <li>Анализа и оцена на ризикот – снимање на ризик</li>
              <br />
              <li>Целосна осигурителна услуга која вклучува:</li>
              <ul>
                <li>
                  Консултирање т.е препорачување на осигурително покритие во
                  зависност од Вашата потреба или карактерот на Вашиот бизнис;
                </li>

                <li>
                  Организирање и спроведување избор на осигурителна компанија
                  врз база на предложени услови за видовите осигурителни
                  покритија;
                </li>
                <li>
                  Дејствува во разработување и предлагање на осигурителни
                  продукти за Вас и оптимизирање на Вашите осигурителни
                  покритија во поглед на цената, продуктот и времетраењето;
                </li>
                <li>
                  Врши анализа и оцена на предложените понуди за осигурување,
                  дава предлози за избор на осигурувач во повеќе варијанти по
                  однос на видови осигурителни покритија и поделба на ризиците;
                </li>
                <li>
                  Подготовка на договорената информација и документација во
                  сооднос со избраната осигурителна компанија;
                </li>
                <li>
                  Постојан контакт со Вас и навремено запознавање со евентуало
                  настанати промени во општите услови за осигурување на
                  осигурителната компанија
                </li>
              </ul>
              <br />
              <li>При настанување на осигуран случај (штета):</li>
              <ul>
                <li>Целосно услужување при настанување на осигуран случај;</li>
                <li>
                  Доверено лице од страна на брокерот кое ќе биде постојано со
                  Вас при увидот на штетата;
                </li>
                <li>
                  Укажување на помош пред соодветните органи за комплетирање и
                  доставување на неопходни документи;
                </li>
                <li>Брзина при решавање на штетите;</li>
              </ul>
            </ol>
          </div>
        </Fade>
      </div>
      <Footer />
    </div>
  );
}

export default Uslugi;
