import React from "react";
import { bool } from "prop-types";
import { StyledMenu } from "./menu.styled";
import { BrowserRouter as Router, Switch, NavLink } from "react-router-dom";

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open}>
      <ul>
        <li>
          <NavLink exact to="/" onClick={() => setOpen(!open)}>
            ДОМА
          </NavLink>
        </li>
        <li>
          <NavLink to="/zanas" onClick={() => setOpen(!open)}>
            ЗА НАС
          </NavLink>
        </li>
        <li>
          <NavLink to="/uslugi" onClick={() => setOpen(!open)}>
            УСЛУГИ
          </NavLink>
        </li>
        <li>
          <NavLink to="/regulativa" onClick={() => setOpen(!open)}>
            РЕГУЛАТИВА
          </NavLink>
        </li>
        <li>
          <NavLink to="/kontakt" onClick={() => setOpen(!open)}>
            КОНТАКТ
          </NavLink>
        </li>
      </ul>
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: bool.isRequired,
};
export default Menu;
