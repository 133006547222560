import React, { Componenet } from "react";
import Header from "./header";
import Navbar from "./navbar";
import Footer from "./footer";
import Fade from "react-reveal/Fade";
import "../css/zanas.css";

function Zanas() {
  return (
    <div>
      <Header />
      <Navbar />

      <div className="zanas-container">
        <h1 className="zanas-title">
          <Fade left cascade>
            ЗА НАС:
          </Fade>
        </h1>
        <Fade>
          <div className="zanas-text-container">
            <p className="zanas-text prv">
              Осигурителното брокерско друштво{" "}
              <b>ДЕЛТА ИНС БРОКЕР AД Скопје </b>е основано во 2009 година, и
              лиценцирано од страна на Министерството за финансии, со Решение
              број: 12-5156/4 од 06.04.2009 година за издавање дозвола за вршење
              на осигурително брокерски работи.
            </p>
            <h1 className="predmet">
              Предмет на работење на Друштвото го сочинува вршење на следните
              дејности:
            </h1>
            <p className="zanas-text tocki">
              •Посредување во договарање на осигурителното и реосигурителното
              покритие на осигурениците;
              <br />
              •Посредување при реализирање на оштетни побарувања по остварен
              осигурен штетен настан;
              <br />
              •Снимање на ризици;
              <br />
              •Посредување при продажба на остатоците од осигурани оштетени
              предмети; <br />
              •Изготвува адекватна анализа на ризикот и предлага соодветно
              осигурително покритие на осигуреникот;
              <br /> •Го информира друштвото за осигурување за намерата на
              осигуреникот да склучи договор за осигурување и го запознава
              осигуреникот со условите на полисите и прописите кои го регулираат
              определувањето на износот на премијата; <br />
              •Посредува во име на осигуреникот за склучување на договор за
              осигурување, кој земајќи ги во предвид сите околности од значење
              за ризикот на поединечен случај, ќе му обезбеди на осигуреникот
              најсоодветно покритие;
              <br />
              •Проверување на содржината на полисите за осигурување; <br />
              •Укажување помош во текот на важењето на договорот за осигурување,
              како пред така и по настанување на осигурениот настан а особено да
              обезбеди дека осигуреникот ги извршил сите правни дејствија во
              предвидените рокови кои се предуслов за стекнување и уживање на
              правата кои произлегуваат од договорот за осигурување;
            </p>
            <h1 className="nadomest">
              Согласно Законот за супервизија на осигурувањето, нашите услуги за
              клиентите, правни и физички лица, се без надомест.
            </h1>
            <p className="zanas-text posledno">
              <b>ДЕЛТА ИНС БРОКЕР АД Скопје</b> има склучено договори за
              соработка со сите осигурителни компании во земјата каде се
              регулира правата и обврските помеѓу осигурителните компании и
              брокерското друштво по класи на осигурување. <br />
              <br />
              Брокерското друштво <b>ДЕЛТА ИНС БРОКЕР АД Скопје</b> има полиса
              од одговорност од дејност на сума на осигурување од 250.000 евра и
              годишен агрегат од 500.000 евра во денарска противвредност во
              УНИКА А.Д. Скопје , која се однесува на целокупното работење на
              друштвото. <br /> <br />
              Во реализација на своите стратешки вредности насочени сме кон
              обезбедување на повисоко ниво на квалитетни услуги при понудата на
              нашите производи. Ова резултира со развивање на најдобри
              осигурителни производи, антиципирајќи ги потребите на пазарот.
              Стратешка цел е обезбедување на оптимален квалитет на осигурување
              со имплементирање високо ниво на услуга во сите сегменти од
              спроведувањето на осигурителниот процес.
            </p>
          </div>
        </Fade>
      </div>
      <Footer />
    </div>
  );
}

export default Zanas;
