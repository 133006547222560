import React from "react";
import Fade from "react-reveal/Fade";
import "../css/ponuda.css";

function Ponuda() {
  const cards = [
    {
      id: 1,
      name: "ОДГОВОРНОСТ",
      imageUrl: "./images/odgovornost.jpg",
      text:
        "Осигурување од општа одговорност ја покрива штетата која што настанала како резултат на неочекуван и изненадувачки настан во извршување на дејноста на осигуреникот, за којашто е одговорно друго лице.",
    },
    {
      id: 2,
      name: "МОТОРНИ ВОЗИЛА",
      imageUrl: "./images/motorni vozila.jpg",
      text:
        "Осигурувањето од автомобилска одговорност е задолжителен вид осигурување, неопходно пред да се вклучите со возилото во сообраќај.",
    },
    {
      id: 3,
      name: "ПАТНИЧКО",
      imageUrl: "./images/patnicko.jpg",
      text:
        "Патничко осигурување е една од најважните работи кои треба да ги имате со себе кога патувате надвор од државата. Ова осигурување ве штити од трошоци во случај на нужна потреба од медицинска помош во странство.",
    },
    {
      id: 4,
      name: "ЖИВОТ",
      imageUrl: "./images/zivot.jpg",
      text:
        "Вашиот живот е скапоцен – осигурајте се за да Ви биде удобен и сигурен. Иднината не можете да ја предвидите, но можете да ја планирате! Доколку размислувате за планирање на сопствената иднина, направете го тоа со добра причина,.",
    },
    {
      id: 5,
      name: "ТРАНСПОРТ",
      imageUrl: "./images/transport.jpg",
      text:
        "Oсигурување на пратки за сите клиенти кои се занимаваат со увоз или извоз на стоки, покритие на пратките кои патуваат на ризик на осигуреникот, од местото на натовар до местото на растовар.",
    },
    {
      id: 6,
      name: "ИМОТ",
      imageUrl: "./images/imot.jpg",
      text:
        "Со полисата за осигурување на имот заштитете го својот дом од ненадејна штета: пожар,поплава, елементарна непогода или друга непредвидена штета.",
    },
  ];

  return (
    <div className="ponuda-container">
      <Fade left cascade>
        <h2 className="pon-title">ОСИГУРИТЕЛНА ПОНУДА</h2>
      </Fade>

      <div className="cards-container">
        {cards.map((card) => (
          <Fade key={card.id}>
            <div className="card-whole">
              <h4 className="name-ponuda">
                <Fade left cascade>
                  {card.name}
                </Fade>
              </h4>
              <div className="card">
                <img
                  className="img-ponuda"
                  src={card.imageUrl}
                  alt="ponuda"
                ></img>
                <p className="text-ponuda">{card.text}</p>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
}

export default Ponuda;
