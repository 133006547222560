import React from "react";
import "../css/footer.css";
import face_icon from "../images/face_icon.svg";
import twit_icon from "../images/twit_icon.svg";
import insta_icon from "../images/insta_icon.svg";
import Fade from "react-reveal/Fade";

function Footer() {
  return (
    <Fade>
      <div className="footer">
        <div className="grey">
          <div className="social-icons">
            <a href="#">
              <img
                className="face_ico icon"
                src={face_icon}
                alt="facebook_icon"
              ></img>
            </a>
            <a href="#">
              <img
                className="twit_ico icon"
                src={twit_icon}
                alt="twitter_icon"
              ></img>
            </a>
            <a href="#">
              <img
                className="insta_ico icon"
                src={insta_icon}
                alt="instagram_icon"
              ></img>
            </a>
          </div>

          <div className="timot">
            <span>
              Тимот на ДЕЛТА ИНС БРОКЕР е тука за Вас да Ви помогне да го
              направите најдобриот избор!
              <br />
              <br />
              <div className="agenti-footer">
                <div className="agent Ivan">
                  Иван Клинчаров <br />
                  број на лиценца ОБ-0446 <br />
                  <a href="mailto:ivan@deltains.mk">ivan@deltains.mk</a>
                </div>
                <div className="agent Biljana">
                  Билјана Иванова
                  <br />
                  број на лиценца ОБ-0550
                  <br />
                  <a href="mailto:biljanadeltains@gmail.com">
                    biljanadeltains@gmail.com
                  </a>
                </div>
              </div>
            </span>
          </div>
          <div className="kontakt-info">
            <span>
              КОНТАКТ ИНФОРМАЦИИ <br />
              Адреса: Ул. Максим Горки 16/3 Скопје <br />
              Телефон: 02-620-1001 / 076 422 200 <br />
              Е-пошта: <a href="mailto:biljana@deltains.mk">ivan@deltains.mk</a>
            </span>
          </div>
        </div>
        <div className="garantirame">
          <span>
            Долгогодишното искуство и професионализам во областа на
            осигурувањето нѝ дава за право да ја{" "}
            <b>ГАРАНТИРАМЕ ВАШАТА СИГУРНОСТ</b>
          </span>
        </div>
      </div>
    </Fade>
  );
}

export default Footer;
