import React, { Component } from "react";
import logoLoad from "../images/Logo Animation_3.gif";
import "../css/loadingScreen.css";

function LoadingScreen() {
  return (
    <div className="load-container">
      <img className="loadLogo" src={logoLoad}></img>
    </div>
  );
}

export default LoadingScreen;
