import React, { Component } from "react";
import { BrowserRouter as Router, Switch, NavLink } from "react-router-dom";
import "../css/navbar.css";

function Navbar() {
  return (
    <div className="navbar">
      <a href="mailto:ivan@deltains.mk" className="info">
        ivan@deltains.mk
      </a>
      <ul>
        <li>
          <NavLink exact className="navlink" to="/">
            ДОМА
          </NavLink>
        </li>
        <li>
          <NavLink className="navlink" to="/zanas">
            ЗА НАС
          </NavLink>
        </li>
        <li>
          <NavLink className="navlink" to="/uslugi">
            УСЛУГИ
          </NavLink>
        </li>
        <li>
          <NavLink className="navlink" to="/regulativa">
            РЕГУЛАТИВА
          </NavLink>
        </li>
        <li>
          <NavLink className="navlink" to="/kontakt">
            КОНТАКТ
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
