import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(26, 94, 140, 0.8);
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 9;

  @media (max-width: #555) {
    width: 100%;
  }

  li {
    list-style: none;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.3rem;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s linear;
    padding: 3%;
    @media (max-width: #666) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #f05a29;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
